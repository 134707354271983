<template>
  <div class="">
    <div class="header">
      <div class="view">
        <img src="/img/space/banner_1.png" class="banner_1" alt="" />
        <img src="/img/space/banner_2.png" class="banner_2" alt="" />
      </div>
    </div>

    <div class="header_mask"></div>
    <div class="main">
      <div class="grid">
        <div class="scenePic">
          <div class="pic" style="background-image: url(/img/space/1.png)">
            <div class="inner">
              <p>厨房</p>
              <span>KITCHEN</span>
            </div>
          </div>
          <div class="back">
            <div class="inner">
              <p>厨房</p>
            </div>
          </div>
        </div>
        <div class="scenePic">
          <div class="pic" style="background-image: url(/img/space/2.png)">
            <div class="inner">
              <p>卫生间</p>
              <span>TOILET</span>
            </div>
          </div>
          <div class="back">
            <div class="inner">
              <p>卫生间</p>
            </div>
          </div>
        </div>
        <div class="scenePic">
          <div class="pic" style="background-image: url(/img/space/3.png)">
            <div class="inner">
              <p>阳台</p>
              <span>BALCONY</span>
            </div>
          </div>
          <div class="back">
            <div class="inner">
              <p>阳台</p>
            </div>
          </div>
        </div>
        <div class="scenePic">
          <div class="pic" style="background-image: url(/img/space/4.png)">
            <div class="inner">
              <p>X空间</p>
              <span>X SPACE</span>
            </div>
          </div>
          <div class="back">
            <div class="inner">
              <p>X空间</p>
            </div>
          </div>
        </div>
        <div class="scenePic">
          <div class="pic" style="background-image: url(/img/space/5.png)">
            <div class="inner">
              <p>玄关</p>
              <span>PORCH</span>
            </div>
          </div>
          <div class="back">
            <div class="inner">
              <p>玄关</p>
            </div>
          </div>
        </div>
        <div class="scenePic">
          <div class="pic" style="background-image: url(/img/space/6.png)">
            <div class="inner">
              <p>衣帽间</p>
              <span>CLOAKROOM</span>
            </div>
          </div>
          <div class="back">
            <div class="inner">
              <p>衣帽间</p>
            </div>
          </div>
        </div>
        <div class="scenePic">
          <div class="pic" style="background-image: url(/img/space/7.png)">
            <div class="inner">
              <p>儿童房</p>
              <span>CHILDREN'S ROOM</span>
            </div>
          </div>
          <div class="back">
            <div class="inner">
              <p>儿童房</p>
            </div>
          </div>
        </div>
        <div class="scenePic">
          <div class="pic" style="background-image: url(/img/space/8.png)">
            <div class="inner">
              <p>卧室</p>
              <span>BEDROOM</span>
            </div>
          </div>
          <div class="back">
            <div class="inner">
              <p>卧室</p>
            </div>
          </div>
        </div>
        <div class="scenePic">
          <div class="pic" style="background-image: url(/img/space/9.png)">
            <div class="inner">
              <p>客餐厅</p>
              <span>THE RESTAURANT</span>
            </div>
          </div>
          <div class="back">
            <div class="inner">
              <p>客餐厅</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="spaceFooter">
      <footer-component></footer-component>
    </div>
  </div>
</template>

<script>
import { spaceMoving } from '../assets/js/spaceMoving.js'
import Footer from './Footer.vue'
import '../assets/css/spaceImgStyle.css'
export default {
  name: 'Space',
  data() {
    return {}
  },
  components: {
    'footer-component': Footer
  },
  created() {},
  mounted() {
    this.$nextTick(() => {
      this.setMoving()
    })
  },
  methods: {
    setMoving() {
      spaceMoving()
    }
  }
}
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}

.header {
  height: 900px;
  position: relative;
  overflow: hidden;

  --percentage: 0.5;
}
.header .view {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.header img {
  position: absolute;
  display: block;
  width: 120%;
  height: 100%;
  object-fit: cover;
}
.header .banner_1 {
  z-index: 20;
  opacity: calc(1 - (var(--percentage) - 0.5) / 0.5);
}

.header .banner_2 {
  /* z-index: 10;
  opacity: calc(1 - (var(--percentage) - 0.5) / 0.5); */
}
.header .view {
  transform: translatex(calc(var(--percentage) * 100px));
  -webkit-transform: translatex(calc(var(--percentage) * 100px));
  -moz-transform: translatex(calc(var(--percentage) * 100px));
  -ms-transform: translatex(calc(var(--percentage) * 100px));
  -o-transform: translatex(calc(var(--percentage) * 100px));
}
.header .view,
.header .banner_1,
.header .banner_2 {
  transition: 0.2s all ease-in;
}
.header.moving .view,
.header.moving .banner_1,
.header.moving .banner_2 {
  transition: none;
}

.header .window-cover {
  opacity: calc((var(--percentage) - 0.9) / 0.1);
}

.header_mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 1920px;
  height: 100px;
  background-color: #1d1b1b;
  opacity: 0.3;
}
.main {
  position: absolute;
  top: 900px;
  left: 0;
  width: 1920px;
  height: 1153px;
  background-color: #e0e0e0;
}
.grid {
  position: absolute;
  top: 70px;
  left: 50%;
  transform: translateX(-50%);
  width: 1120px;
  height: 1013px;
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 相当于 1fr 1fr 1fr */
  grid-template-rows: repeat(3, 1fr); /* fr单位可以将容器分为几等份 */
  grid-gap: 1%; /* grid-column-gap 和 grid-row-gap的简写 */
  grid-auto-flow: row;
}

.publicTitle {
  padding-top: 20px;
  padding-left: 20px;
}
.p1_style {
  position: relative;
  text-align: left;
  font-size: 30px;
}
.p2_style {
  position: relative;
  text-align: left;
  font-size: 24px;
}

.spaceFooter {
  position: absolute;
  top: 2053px;
  left: 0;
  width: 1920px;
  height: 200px;
}
</style>
